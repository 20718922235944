<template>
  <Html
    :lang="head.htmlAttrs.lang"
    :dir="head.htmlAttrs.dir"
  >
    <Head>
      <Title>{{ title }}</Title>
    </Head>
    <Body :class="theme">
      <UiComponentWrapper id="app">
        <!-- Section > Layout -->
        <UiComponentWrapper class="app__layout">
          <UiComponentWrapper class="app__layout-body">
            <slot />
          </UiComponentWrapper>
        </UiComponentWrapper>

        <!-- Modals -->
        <template
          v-for="currentForm in data.allForms"
          :key="`${currentForm.slug}-form`"
        >
          <Transition
            @enter="onEnter"
            @leave="onLeave"
          >
            <UiComponentModal
              v-if="forms[currentForm.slug].isVisible && !forms[currentForm.slug].hasBeenSubmitted"
              class="app__modal demo"
              :class="{
                experience: isEnhanced && !forms[currentForm.slug].hasBeenSubmitted,
              }"
              @modal:close="forms[currentForm.slug].isVisible = false"
            >
              <template #title>
                {{ currentForm.firstStepTitle }}
              </template>
              <template #description>
                {{ currentForm.firstStepText }}
              </template>
              <template #content>
                <UiComponentWrapper class="content">
                  <UiComponentWrapper class="left">
                    <UiComponentImage
                      v-if="data.componentForm.background && modalHeight && modalWidth"
                      :key="`${modalHeight}-${modalWidth}`"
                      :alt="data.componentForm.background.alt"
                      class="pattern"
                      :expected-size="modalWidth"
                      :extra-params="{ fit: 'crop', h: modalHeight, w: modalWidth }"
                      :height="modalHeight"
                      :lazyload="false"
                      :orientation="
                        modalHeight > modalWidth ? 'portrait' : 'landscape'
                      "
                      :source="data.componentForm.background.url"
                      :width="modalWidth"
                    />
                    <UiComponentHeading
                      as="paragraph"
                      :level="2"
                    >
                      {{
                        data.componentForm.title
                      }}
                    </UiComponentHeading>
                    <UiComponentHeading
                      as="paragraph"
                      :level="3"
                    >
                      {{
                        data.componentForm.title
                      }}
                    </UiComponentHeading>
                    <div
                      class="description"
                      v-html="$md.render(data.componentForm.text)"
                    />
                    <UiComponentWrapper class="testimonial">
                      <UiComponentImage
                        alt="Logo"
                        class="logo"
                        :expected-size="80"
                        :extra-params="{ bri: 100 }"
                        :height="data.componentForm.testimonial.logo.height"
                        :source="data.componentForm.testimonial.logo.url"
                        :width="data.componentForm.testimonial.logo.width"
                      />
                      <UiComponentParagraph
                        class="quote"
                        size="s"
                      >
                        {{
                          data.componentForm.testimonial.quote
                        }}
                      </UiComponentParagraph>
                      <UiComponentWrapper class="author">
                        <UiComponentImage
                          :alt="data.componentForm.testimonial.authorName"
                          class="picture"
                          :expected-size="32"
                          :height="data.componentForm.testimonial.authorPicture.height"
                          :source="data.componentForm.testimonial.authorPicture.url"
                          :width="data.componentForm.testimonial.authorPicture.width"
                        />
                        <UiComponentParagraph class="name">
                          {{
                            data.componentForm.testimonial.authorName
                          }}
                        </UiComponentParagraph>
                        <UiComponentParagraph class="job">
                          {{
                            data.componentForm.testimonial.authorJob
                          }}
                        </UiComponentParagraph>
                      </UiComponentWrapper>
                    </UiComponentWrapper>
                    <UiComponentWrapper class="logos">
                      <UiComponentWrapper
                        v-for="i in Math.ceil(customersLogos.length / 4)"
                        :key="i"
                        class="row"
                      >
                        <UiComponentWrapper
                          v-for="j in 4"
                          :key="`${i}-${j}`"
                          class="cell"
                        >
                          <UiComponentImage
                            :alt="customersLogos[(i - 1) * 4 + (j - 1)].name"
                            class="logo"
                            :expected-size="80"
                            :extra-params="{ bri: 100 }"
                            :height="customersLogos[(i - 1) * 4 + (j - 1)].height"
                            :source="customersLogos[(i - 1) * 4 + (j - 1)].url"
                            :width="customersLogos[(i - 1) * 4 + (j - 1)].width"
                          />
                        </UiComponentWrapper>
                      </UiComponentWrapper>
                    </UiComponentWrapper>
                  </UiComponentWrapper>
                  <UiComponentWrapper class="right">
                    <UiComponentWrapper class="headline">
                      <UiComponentImage
                        alt="Flag"
                        class="flag fr"
                        :expected-size="16"
                        :height="16"
                        orientation="portrait"
                        source="https://livestorm.imgix.net/1127/1646403152-flag-of-france.svg"
                        :width="24"
                      />
                      <UiComponentImage
                        alt="Flag"
                        class="flag uk"
                        :expected-size="16"
                        :height="16"
                        orientation="portrait"
                        source="https://livestorm.imgix.net/1127/1646403153-flag-of-the-united-kingdom.svg"
                        :width="24"
                      />
                      <UiComponentImage
                        alt="Flag"
                        class="flag us"
                        :expected-size="16"
                        :height="16"
                        orientation="portrait"
                        source="https://livestorm.imgix.net/1127/1646403153-flag-of-the-united-states.svg"
                        :width="24"
                      />
                      <UiComponentParagraph size="s">
                        A Webinar Solution used by the best US companies for their
                        business
                      </UiComponentParagraph>
                    </UiComponentWrapper>
                    <UiComponentWrapper
                      v-if="currentFormIndex !== -1"
                      class="options"
                    >
                      <UiComponentWrapper
                        class="item"
                        :class="{ selected: currentFormIndex === 0 }"
                        @click="setFormIndex(0)"
                      >
                        <UiComponentImage
                          :alt="data.componentFormOptions.demoOptionTitle"
                          class="icon"
                          :expected-size="16"
                          :height="16"
                          source="https://livestorm.imgix.net/1127/1694504711-book-a-call.svg"
                          :width="16"
                        />
                        <UiComponentWrapper class="description">
                          <UiComponentParagraph>{{ data.componentFormOptions.demoOptionTitle }}</UiComponentParagraph>
                          <UiComponentParagraph>{{ data.componentFormOptions.demoOptionText }}</UiComponentParagraph>
                        </UiComponentWrapper>
                      </UiComponentWrapper>
                      <UiComponentWrapper
                        class="item"
                        :class="{ selected: currentFormIndex === 1 }"
                        @click="setFormIndex(1)"
                      >
                        <UiComponentImage
                          :alt="data.componentFormOptions.watchAVideoOptionTitle"
                          class="icon"
                          :expected-size="16"
                          :height="16"
                          source="https://livestorm.imgix.net/1127/1694504712-watch-a-video.svg"
                          :width="16"
                        />
                        <UiComponentWrapper class="description">
                          <UiComponentParagraph>{{ data.componentFormOptions.watchAVideoOptionTitle }}</UiComponentParagraph>
                          <UiComponentParagraph>{{ data.componentFormOptions.watchAVideoOptionText }}</UiComponentParagraph>
                        </UiComponentWrapper>
                      </UiComponentWrapper>
                    </UiComponentWrapper>
                    <OrganismHubspotNativeForm
                      v-if="currentFormIndex === -1"
                      :form-id="currentForm.formId"
                      :unique-id="`${currentForm.slug}-modal`"
                      @hubspot:form:ready="onReady"
                      @hubspot:form:submitted="onSubmitted(currentForm, $event)"
                    />
                    <OrganismHubspotNativeForm
                      v-else-if="currentFormIndex === 0"
                      :form-id="demoForm.formId"
                      unique-id="form-demo-modal"
                      @hubspot:form:submitted="onSubmitted(demoForm, $event)"
                    />
                    <OrganismHubspotNativeForm
                      v-else
                      :form-id="watchVideoForm.formId"
                      unique-id="form-watch-a-video-modal"
                      @hubspot:form:submitted="onSubmitted(watchVideoForm, $event)"
                    />
                  </UiComponentWrapper>
                </UiComponentWrapper>
              </template>
            </UiComponentModal>
          </Transition>

          <Transition
            @enter="onEnter"
            @leave="onLeave"
          >
            <UiComponentModal
              v-if="forms[currentForm.slug].isVisible && forms[currentForm.slug].hasBeenSubmitted && !isLoading"
              class="app__modal success"
              @modal:close="forms[currentForm.slug].isVisible = false"
            >
              <template
                v-if="currentForm.lastStepTitle"
                #title
              >
                {{ currentForm.lastStepTitle }}
              </template>
              <template #content>
                <UiComponentWrapper class="flex-col">
                  <div
                    v-if="currentForm.lastStepText"
                    class="message"
                    v-html="$md.render(currentForm.lastStepText)"
                  />
                  <AtomButton
                    v-if="currentForm.lastStepCta"
                    class="mt-6"
                    size="l"
                    :action="getActionFromSlug(currentForm.lastStepCta.slug)"
                    :to="currentForm.lastStepCta.slug"
                  >
                    {{ currentForm.lastStepCta.slot }}
                  </AtomButton>
                </UiComponentWrapper>
              </template>
            </UiComponentModal>
          </Transition>
        </template>
      </UiComponentWrapper>
    </Body>
  </Html>
</template>

<script setup>
import { gsap } from 'gsap'

// Head
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

// Composables
const { track } = useAnalyticsJS()
useChilipiper()
const { state } = useConditionalPreviewMode()
const { isEnhanced } = useEnhancedModal()
const { currentFormIndex, setFormIndex } = useForm()
const { locale } = useI18n()
const { isLogged } = useIsLoggedIn()
const { $md } = useMarkdown()
const { meta } = useRoute()
const { public: publicRuntimeConfig } = useRuntimeConfig()
const isLoading = useState('loader', () => false)
const theme = useState('color-scheme', () => 'light')

// Async Data
const { data } = await useFetch(`/api/content/layouts/ads/${locale.value}`, {
  key: `layout-ads-${locale.value}`,
  query: {
    preview: state.preview,
  },
})

// States
const forms = useState('forms', () => {
  return data.value.allForms.reduce((accumulator, currentForm) => {
    return {
      ...accumulator,
      [currentForm.slug]: {
        hasBeenSubmitted: false,
        isVisible: false,
      },
    }
  }, {})
})

// Refs
const modalHeight = ref(null)
const modalWidth = ref(null)

// Computed Properties
const customersLogos = computed(() => {
  return data.value.componentForm.customers
    .filter(({ logo }) => logo)
    .map(({ logo }) => logo)
})

const demoForm = computed(() => {
  return data.value.allForms.find(({ slug }) => slug === 'demo')
})

const signupOrUpgradeCallToAction = computed(() => {
  return isLogged.value
    ? {
        action: 'Upgrade clicked',
        to: `https://${publicRuntimeConfig.appBaseUrl}/#/settings?page=settings&tab=billing`,
        slot: data.value.componentGlobal.ctaUpgradeNow,
      }
    : {
        action: 'Signup clicked',
        to: `https://${publicRuntimeConfig.appBaseUrl}/#/signup`,
        slot: data.value.componentGlobal.ctaGetStartedForFree,
      }
})

const title = computed(() => {
  return meta.title || 'Livestorm'
})

const watchVideoForm = computed(() => {
  return data.value.allForms.find(({ slug }) => slug === 'watch-video')
})

// Methods
function getActionFromSlug(slug) {
  if (slug === `https://${publicRuntimeConfig.appBaseUrl}/#/signup`) {
    return signupOrUpgradeCallToAction.value.action
  }
  else if (callToAction.to === '#form-demo') {
    return 'Demo clicked'
  }
  else {
    return 'Button clicked'
  }
}

function onEnter($el, done) {
  gsap.from(
    $el.querySelector('.background'),
    {
      autoAlpha: 0,
      duration: 0.6,
      ease: 'expo.out',
    },
  )
  gsap.from(
    $el.querySelector('.box'),
    {
      autoAlpha: 0,
      delay: 0.15,
      duration: 0.6,
      ease: 'expo.out',
      onComplete: () => {
        done()
      },
      scale: 0.9,
      y: 80,
    },
  )
}

function onLeave($el, done) {
  gsap.to(
    $el.querySelector('.box'),
    {
      autoAlpha: 0,
      duration: 0.3,
      ease: 'expo.in',
      scale: 0.9,
      y: 80,
    },
  )
  gsap.to(
    $el.querySelector('.background'),
    {
      autoAlpha: 0,
      delay: 0.075,
      duration: 0.3,
      ease: 'expo.in',
      onComplete: () => {
        done()
      },
    },
  )
}

function onReady() {
  onObserve()
}

function onObserve() {
  if (document.querySelector('.app .modal .box')) {
    const { height, width } = document
      .querySelector('.app .modal .box')
      .getBoundingClientRect()

    modalHeight.value = Number.parseInt(height)
    modalWidth.value = Number.parseInt(width)
  }
}

async function onSubmitted(currentForm, submittedValues) {
  // Trigger event to Google Tag Manager
  if (window.dataLayer && currentForm.gtmEvent) {
    const { v4: uuidv4 } = await import('uuid')
    dataLayer.push({ event: `${currentForm.gtmEvent}.${uuidv4()}` }) // Send event to GTM
  }

  // Trigger event to Segment
  const properties = currentForm.segmentWhitelistedProperties
    ? currentForm.segmentWhitelistedProperties
      .split(',')
      .map(value => value.trim())
      .reduce((accumulator, value) => {
        return {
          ...accumulator,
          [value]: submittedValues[value] ? submittedValues[value] : undefined,
        }
      }, {})
    : submittedValues

  await track(currentForm.segmentEvent || 'Form submitted', {
    ...properties,
    category: 'Form',
    form_id: currentForm.formId,
    label: currentForm.segmentLabel || currentForm.segmentEvent || 'Form submitted',
    locale: locale.value,
    title: window.document.title,
  })

  if (currentForm.redirectUrl) {
    window.location = `${publicRuntimeConfig.baseUrl}${currentForm.redirectUrl}`
  }
  else if (currentForm.slug === 'demo') {
    isLoading.value = true
    window.setTimeout(() => {
      forms.value.demo.hasBeenSubmitted = true
    }, 1000)
  }
  else {
    forms.value[currentForm.slug].hasBeenSubmitted = true
  }
}

function toggleScrollOnBody() {
  if (Object.values(forms.value).some(({ isVisible }) => isVisible)) {
    document.body.classList.add('no-scroll')
  }
  else {
    document.body.classList.remove('no-scroll')
  }
}

// Watchers
watch(
  () => forms.value,
  () => {
    toggleScrollOnBody()
  },
  { deep: true },
)

// Lifecycle Hooks
onBeforeUnmount(() => {
  window.removeEventListener('resize', onObserve)
})

onMounted(() => {
  window.addEventListener('resize', onObserve)
})
</script>

<style lang="postcss" scoped>
#app {
  @apply flex-col items-center;

  .app__banner {
    &.event {
      @apply hidden;

      @screen md {
        @apply flex;
      }

      :deep(.highlighted) {
        @apply inline-flex items-center;

        &:hover {
          i {
            @apply translate-x-2 !important;
          }
        }

        i {
          @apply ml-2 transform translate-x-0 transition-transform duration-300 ease-in-out !important;
        }
      }
    }
  }

  .app__layout {
    @apply flex-col items-center;

    .app__layout-header {
      @apply sticky top-0 flex-row justify-center w-full z-40 transform transition-all duration-300 ease-in-out;

      &.hide {
        @apply -translate-y-full;
      }

      &.scrolled {
        @apply bg-white border-b border-grey-blue-200;
      }

      .container {
        &.opened {
          @apply bg-white;
        }
      }
    }

    .app__layout-body,
    .app__layout-footer {
      @apply flex-col items-center;
    }
  }

  :deep(.app__modal) {
    &.agency,
    &.demo,
    &.referral,
    &.user-group  {
      .box {
        .body {
          .content {
            .left {
              @apply hidden;
            }

            .right {
              @apply flex-col;

              .headline {
                @apply hidden;
              }

              .options {
                @apply flex-col;

                @screen md {
                  @apply flex-row flex-wrap;
                }

                & + .native-form {
                  @apply mt-6;
                }

                .item {
                  @apply p-4 bg-white border border-grey-blue-300 cursor-pointer rounded;

                  @screen md {
                    width: calc((100% - 32px) / 2);
                  }

                  &.selected {
                    @apply bg-livestorm-blue-100 border-livestorm-blue-600 cursor-default;
                  }

                  &:last-child {
                    @apply mt-4;

                    @screen md {
                      @apply mt-0 ml-8;
                    }
                  }

                  .icon {
                    @apply flex-shrink-0 mt-1 rounded-none;
                  }

                  .description {
                    @apply flex-col ml-2;

                    p {
                      @apply font-medium text-winter-green-900 text-base leading-6;

                      & + p {
                        @apply mt-[2px] font-normal text-grey-blue-600 text-xs leading-[18px];
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.experience {
      &.with-banner {
        .box {
          > img {
            @screen xl {
              top: 24px;
              right: 28px;
            }
          }

          .body {
            .content {
              .right {
                .headline {
                  @screen xl {
                    @apply flex;
                  }
                }
              }
            }
          }
        }
      }

      &.with-small-title {
        .box {
          .body {
            .content {
              .left {
                .h2 {
                  @apply hidden;
                }

                .h3 {
                  @apply flex;
                }
              }
            }
          }
        }
      }

      &.with-fr-flag {
        .box {
          .body {
            .content {
              .right {
                .headline {
                  .flag {
                    &.fr {
                      @apply flex;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.with-uk-flag {
        .box {
          .body {
            .content {
              .right {
                .headline {
                  .flag {
                    &.uk {
                      @apply flex;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.with-us-flag {
        .box {
          .body {
            .content {
              .right {
                .headline {
                  .flag {
                    &.us {
                      @apply flex;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .box {
        @screen xl {
          @apply p-0 overflow-hidden;
          max-width: 1060px !important;
        }

        .head {
          @screen xl {
            @apply hidden;
          }
        }

        .body {
          @screen xl {
            @apply mt-0;
          }

          .content {
            @screen xl {
              @apply flex-row;
            }

            .left,
            .right {
              @screen xl {
                @apply flex-col w-1/2;
              }
            }

            .left {
              @screen xl {
                @apply relative flex min-h-[700px] bg-livestorm-blue-700 text-white p-10;
              }

              .background {
                @apply absolute inset-0 rounded-none z-0;
              }

              .h2,
              .h3 {
                @apply text-white z-10;
              }

              .h3 {
                @apply hidden;
              }

              .description {
                @apply font-medium text-white whitespace-normal mt-2 mb-18 leading-6 z-10;
              }

              .testimonial {
                @apply flex-col w-full mt-auto p-6 border-2 border-livestorm-blue-200 rounded-4xl z-10;
                background: rgba(255, 255, 255, 0.1);

                .logo {
                  @apply rounded-none;
                }

                .quote {
                  @apply text-grey-blue-100 whitespace-normal mt-4 leading-6;
                }

                .author {
                  @apply items-center w-full mt-4;

                  .picture {
                    @apply flex-shrink-0 border border-grey-blue-100 rounded-full;
                  }

                  .info {
                    @apply flex-col ml-3;

                    .name {
                      @apply font-medium text-base text-white leading-6;
                    }

                    .job {
                      @apply text-base text-grey-blue-100 leading-6;
                    }
                  }
                }
              }

              .logos {
                @apply flex-col w-full mt-12;

                .row {
                  @apply w-full justify-between mt-4;

                  &:first-child {
                    @apply mt-0;
                  }

                  .cell {
                    @apply justify-start items-center w-auto;

                    .logo {
                      @apply rounded-none;
                    }
                  }
                }
              }
            }

            .right {
              .headline {
                @screen xl {
                  @apply hidden justify-center w-full pl-10 pr-12 py-5 bg-grey-blue-200;
                }

                .flag {
                  @apply hidden flex-shrink-0 rounded-none mt-1 mr-2;
                }

                p {
                  @apply ml-2 font-medium text-green-900 whitespace-normal leading-6;
                }
              }

              .native-form,
              iframe {
                @screen xl {
                  @apply my-auto h-full p-10;
                }
              }
            }
          }
        }
      }
    }
  }

  :global(.app__modal.success .message) {
    @apply font-normal text-winter-green-900 text-origin leading-7;
  }

  :global(.app__modal.success .message p + p) {
    @apply mt-6;
  }

  :global(.app__modal.success .message p a) {
    @apply text-livestorm-blue-700;
  }
}
</style>
